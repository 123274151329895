import { LoadingButton } from "@mui/lab";
import { getPassBatchReport } from "@utils/api/pass";
import { useCallback, useState } from "react";

type Props = {
  passBatchId: string
}

export function ExportCSV({ passBatchId }:Props) {
  const [loading, setLoading] = useState(false);

  const handleLoad = useCallback(() => {
    setLoading(true);
    getPassBatchReport(passBatchId)
    .then(({ data: blob, request }) => {
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      const filename = request.responseURL?.split('#')[0].split('?')[0].split('/').pop();

      link.setAttribute(
        'download',
        filename,
      );
  
      // Append to html link element page
      document.body.appendChild(link);
  
      // Start download
      link.click();
  
      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    })
    .finally(() => {
      setLoading(false);
    })
  }, []);
  
  return <LoadingButton color="success" loading={loading} onClick={handleLoad}>Export Redeem Code as TXT file</LoadingButton>
}