import { LoadingButton } from "@mui/lab";
import { Button, Stack, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSearchParams } from "react-router-dom";

export function SearchUser() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [id, setId] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    setId(searchParams.get("id") || "");
    setPhone(searchParams.get("phone") || "");
  }, [searchParams]);

  const handleSearch = useCallback(() => {
    setSearchParams({
      ...(id && { id }),
      ...(phone && { phone }),
    })
  }, [id, phone]);

  return <Stack>
    <Stack direction="row" spacing={1}>
      <TextField
        fullWidth
        label="User ID"
        value={id}
        onChange={(e) => setId(e.target.value)}
      />
      <TextField
        fullWidth
        label="Mobile Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
    </Stack>
    <Button fullWidth onClick={handleSearch}>
      <SearchIcon/>
    </Button>
  </Stack>
}