import { User } from "@utils/types/User";
import { api } from ".";

export async function userGet(params:{id?:string, phone?:string}) {
  const response = await api.get("/user", { params });
  
  return response.data;
}

export async function userListGet(size:number, page:number) {
  const response = await api.get("/user", { params: { size, page }});
  
  return response.data;
}

export async function userTopupPost(id:number, amount:string) {
  const response = await api.post<User>("/user/topup", { id, amount });
  
  return response.data;
}