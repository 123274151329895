import { AppBar as MuiAppBar, IconButton, Toolbar, Typography, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu"
import { useMemo, useState } from "react";
import { AppDrawer } from "@components/AppBar/AppDrawer";
import AccountCircle from '@mui/icons-material/AccountCircle';
import { cognitoGetUsername, cognitoUser } from "@utils/cognito";

export function AppBar() {
  const [appDrawerOpen, setAppDrawerOpen] = useState(false);
  const username = useMemo(() => cognitoGetUsername(), [cognitoGetUsername]);

  return <>
    <MuiAppBar position="sticky" sx={{ marginBottom: "1em" }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => setAppDrawerOpen((value) => !value)}
        >
          <MenuIcon />
        </IconButton>
        <Typography sx={{ flexGrow: 1 }}>KMB e-Wallet Admin Dashboard</Typography>
        <Stack direction="row" alignItems="center" gap={1}>
          <AccountCircle />
          <Typography>{username}</Typography>
        </Stack>
      </Toolbar>
    </MuiAppBar>
    <AppDrawer open={appDrawerOpen} setOpen={setAppDrawerOpen}/>
  </>
  
}