import { useCallback, useEffect, useState } from "react";
import { userListGet } from "@utils/api/user";
import { User as UserType } from "@utils/types/User";
import { DataGrid } from "@mui/x-data-grid";

export function UserAll() {
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });
  const [count, setCount] = useState(0);
  const [users, setUsers] = useState<UserType[]>([]);

  const handleLoadUser = useCallback(() => {
    setLoading(true);
    userListGet(paginationModel.pageSize, paginationModel.page).then((userData) => {
      setUsers(userData.rows);
      setCount(userData.count);
    })
    .catch(error => {
      window.alert(error.response?.data?.message || error.message)
    })
    .finally(() => {
      setLoading(false);
    });
  }, [paginationModel]);

  useEffect(handleLoadUser, [handleLoadUser]);

  return <>
    <DataGrid
      style={{ height: "92vh"}}
      loading={loading}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      rowCount={count}
      columns={[
        { field: "id" },
        { field: "phone" }
      ]}
      rows={users}
      pageSizeOptions={[10, 20, 50, 100]}
    />
  </>
}