import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { userTopupPost } from "@utils/api/user";
import { User } from "@utils/types/User";
import React, { useCallback, useMemo, useState } from "react";

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  user: User
}

export function TopupDialog({ open, setOpen, user }: Props) {
  const [loading, setLoading] = useState(false);
  const [topupAmount, setTopupAmount] = useState(0);
  
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleTopup = useCallback(() => {
    if (window.confirm(`Top up ${topupAmount} to user ${user.id}?`)) {
      setLoading(true);
      userTopupPost(user.id, topupAmount.toFixed(2))
      .then(data => {
        window.alert("Top up successfully")
      })
      .finally(() => {
        setLoading(false);
      })
    }

  }, [user, topupAmount]);

  const newBalance = useMemo(() => {
    return (Number(user.amount) + topupAmount).toFixed(2);
  }, [user, topupAmount]);

  return <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
    <DialogTitle>Top Up</DialogTitle>
    <DialogContent>
      <TextField
        style={{ marginTop: "1em" }}
        size="small"
        label="Top up Amount"
        value={topupAmount}
        onChange={e => setTopupAmount(Number(e.target.value))}
        inputProps={{
          /* eslint-disable */
          pattern: "^\d*(\.\d{0,2})?$"
        }}
      />
      <Typography>Original Balance: {user.amount}</Typography>
      <Typography>New Balance: {newBalance}</Typography>
    </DialogContent>
    <DialogActions>
      <LoadingButton loading={loading} onClick={handleTopup}>Top Up</LoadingButton>
    </DialogActions>
  </Dialog>
}