import { Button, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridPaginationModel, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { getPassBatch } from "@utils/api/pass";
import { humanizeDuration } from "@utils/humanizeDuration";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export function PassBatchList() {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const columns = useMemo<GridColDef[]>(() => [
    { field: "id", headerName: "ID", width: 300 },
    { field: "quantity", headerName: "Quantity" },
    { field: "redeemExpiryTime", headerName: "Redeem Expiry Time", width: 200, valueFormatter: ({value}) => moment(value).format("YYYY-MM-DD HH:mm:ss") },
    { field: "validDuration", headerName: "Pass Duration", width: 200, valueFormatter: ({ value }) => humanizeDuration(value) },
    { field: "remarks", headerName: "Remarks", width: 300 },
    { field: "createTime", headerName: "Create Time", width: 200, valueFormatter: ({value}) => moment(value).format("YYYY-MM-DD HH:mm:ss") },
    { field: "Details", renderCell: ({ row }) => <Button target="_blank" href={`/pass/batch/details/${row.id}`}>Go</Button>}
  ], []);

  const paginationModel = useMemo<GridPaginationModel>(() => ({
    pageSize: Number(searchParams.get("size") || 10),
    page: Number(searchParams.get("page") || 1) - 1
  }), [searchParams]);

  const sortModel = useMemo<GridSortModel>(() => {
    const sort = searchParams.get("sort");
    return _.chain(sort || "")
    .split(",")
    .map(item => {
      const [field, sort] = item.split("|");
      if (field && sort) {
        return { field, sort: sort as GridSortDirection };  
      }
    })
    .compact()
    .value();
  }, [searchParams]);

  const handleLoad = useCallback(() => {
    setLoading(true);
    console.log(sortModel);
    getPassBatch({
      sort: sortModel.map(({ field, sort }) => [field, sort].join("|")).join(",") || undefined,
      page: paginationModel.page,
      size: paginationModel.pageSize,
    })
    .then((data) => {
      setData(data.rows);
      setCount(data.count);
    })
    .finally(() => {
      setLoading(false);
    })
  }, [sortModel, paginationModel]);

  const handleSortModelChange = useCallback((model:GridSortModel) => {
    const sortString = model.map(({ field, sort }) => [field, sort].join("|")).join(",");
    setSearchParams(prev => {
      prev.set("sort", sortString);
      return prev;
    });
  }, []);

  const handlePaginationModelChange = useCallback((model:GridPaginationModel) => {
    setSearchParams(prev => {
      prev.set("page", model.page.toString());
      prev.set("size", model.pageSize.toString());
      return prev;
    });
  }, []);

  useEffect(handleLoad, [handleLoad]);
  
  return <Stack spacing={1}>
    <Button fullWidth href="/pass/batch/create" color="success">Create new Pass Batch</Button>
    <DataGrid
      loading={loading}
      autoHeight
      paginationMode={"server"}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
      sortModel={sortModel}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      rowCount={count}
      columns={columns}
      rows={data}
      disableColumnMenu
    />
  </Stack>
}