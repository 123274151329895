import { Stack, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import iso8601Duration from "iso8601-duration";
import moment from "moment";

type Props = {
  duration?: string
  setDuration?: React.Dispatch<React.SetStateAction<string>>
}

export function DurationControl({ duration, setDuration }: Props) {
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [week, setWeek] = useState(0);
  const [day, setDay] = useState(1);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);

  useEffect(() => {
    if (duration && iso8601Duration.pattern.test(duration)) {
      const parsedDuration = iso8601Duration.parse(duration);
      setYear(parsedDuration.years || 0);
      setMonth(parsedDuration.months || 0);
      setWeek(parsedDuration.weeks || 0);
      setDay(parsedDuration.days || 0);
      setHour(parsedDuration.hours || 0);
      setMinute(parsedDuration.minutes || 0);
      setSecond(parsedDuration.seconds || 0);
    }
  }, [duration]);

  useEffect(() => {
    setDuration && setDuration(`P${year}Y${month}M${week}W${day}DT${hour}H${minute}M${second}S`);
  }, [year, month, week, day, hour, minute, second]);

  const baseDate = useMemo(() => moment(), []);

  const endDate = useMemo(() => moment(baseDate).add(moment.duration(duration)), [baseDate, duration]);

  return <Stack>
    <Stack direction="row" spacing={1}>
      <TextField size="small" inputProps={{ type: 'number', min: 0 }} fullWidth label="Year" value={year} onChange={(e) => setYear(Number(e.target.value))}/>
      <TextField size="small" inputProps={{ type: 'number', min: 0 }} fullWidth label="Month" value={month} onChange={(e) => setMonth(Number(e.target.value))}/>
      <TextField size="small" inputProps={{ type: 'number', min: 0 }} fullWidth label="Week" value={week} onChange={(e) => setWeek(Number(e.target.value))}/>
      <TextField size="small" inputProps={{ type: 'number', min: 0 }} fullWidth label="Day" value={day} onChange={(e) => setDay(Number(e.target.value))}/>
      <TextField size="small" inputProps={{ type: 'number', min: 0, max: 23 }} fullWidth label="Hour" value={hour} onChange={(e) => setHour(Number(e.target.value))}/>
      <TextField size="small" inputProps={{ type: 'number', min: 0, max: 59 }} fullWidth label="Minute" value={minute} onChange={(e) => setMinute(Number(e.target.value))}/>
      <TextField size="small" inputProps={{ type: 'number', min: 0, max: 59 }} fullWidth label="Second" value={second} onChange={(e) => setSecond(Number(e.target.value))}/>
    </Stack>
    <Typography sx={{ textAlign: "center" }}>Preview: {baseDate.format("YYYY-MM-DD HH:mm:ss")} ~ {endDate.format("YYYY-MM-DD HH:mm:ss")}</Typography>
  </Stack>
}