export function humanizeDuration(duration: moment.DurationInputObject) {
  let result = [];

  if (duration.years) {
    result.push(`${duration.years} Year`);
  }

  if (duration.months) {
    result.push(`${duration.months} Month`);
  }

  if (duration.weeks) {
    result.push(`${duration.weeks} Week`);
  }

  if (duration.days) {
    result.push(`${duration.days} Day`);
  }

  if (duration.hours) {
    result.push(`${duration.years} Hour`);
  }

  if (duration.minutes) {
    result.push(`${duration.months} Minute`);
  }

  if (duration.seconds) {
    result.push(`${duration.days} Second`);
  }
  return result.join(" ");
}