import { useSearchParams } from "react-router-dom"
import { SearchUser } from "./SearchUser";
import { useCallback, useEffect, useMemo, useState } from "react";
import { userGet } from "@utils/api/user";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { TopupDialog } from "./TopupDialog";
import { User as UserType } from "@utils/types/User";

export function User() {
  const [searchParams] = useSearchParams();
  const id = useMemo(() => searchParams.get("id"), [searchParams]);
  const phone = useMemo(() => searchParams.get("phone"), [searchParams]);

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserType|undefined>();
  const [topupDialogOpen, setTopupDialogOpen] = useState(false);

  const handleLoadUser = useCallback(() => {
    if (id || phone) {
      setLoading(true);
      Promise.all([
        userGet({
          ...(id && { id }),
          ...(phone && { phone }),
        }),
      ]).then(([userData]) => {
        setUser(userData);
      })
      .catch(() => {
        setUser(undefined);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, [id, phone]);

  useEffect(handleLoadUser, [handleLoadUser]);

  const userDetails = useMemo(() => {
    if (id || phone) {
      if (loading) {
        return <Box style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress/>
        </Box>
      }
      if (user) {
        return <Box style={{ marginTop: "1em" }}>
          <Typography>ID: {user.id}</Typography>
          <Typography>Mobile: {user.phone}</Typography>
          {/* <Typography>Club1933 Binding: {user.club1933UserId || "-"}</Typography> */}
          <Typography>Balance: {user.amount}</Typography>
          <Button target="_blank" href={`/txn?userId=${user.id}`}>All Transactions</Button>
          <Button onClick={() => setTopupDialogOpen(true)}>Top Up</Button>
  
          <TopupDialog user={user} open={topupDialogOpen} setOpen={setTopupDialogOpen}/>
        </Box>
      } else {
        return <Typography textAlign={"center"}>User not Found</Typography>;
      }
    }
  }, [id, phone, loading, user, topupDialogOpen]);

  return <>
    <SearchUser/>
    <Box style={{ marginTop: "1em" }}>
      {userDetails}
    </Box>
  </>
}