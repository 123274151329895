import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField } from "@mui/material";
import { txnRefundPost } from "@utils/api/txn";
import { Txn } from "@utils/types/Txn"
import React, { useCallback, useState } from "react";

type Props = {
  row: Txn
}

type RefundDialogProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  row: Txn
}

function RefundDialog({ open, setOpen, setLoading, row }: RefundDialogProps) {
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [amount, setAmount] = useState(-1 * Number(row.amount));
  const [pic, setPic] = useState("");
  const [reason, setReason] = useState("");

  const handleRefund = useCallback((id:string, userId:string, amount:number, reason:string, pic:string) => {
    if (window.confirm(`Are you sure to refund ${amount} points to user ${userId}?`)) {
      setLoading(true);
      txnRefundPost(id, amount, reason, pic)
      .then(() => {
        window.alert("Refunded");
      })
      .catch((error) => {
        window.alert(error.response?.data?.message || error.message);
      })
      .finally(() => {
        setLoading(false);
      })
    }
  }, [setLoading]);

  return <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
    <DialogTitle>Refund</DialogTitle>
    <DialogContent>
      <DialogContentText style={{ marginBottom: "2em" }}>
        User ID: {row.user?.id}
      </DialogContentText>
      <Stack style={{ width: "100%" }} direction="column" spacing={2}>
        <TextField fullWidth label="Amount" value={amount} onChange={(e) => setAmount(Number(e.target.value))} inputProps={{
          type: "number",
          min: "0",
          max: row.amount,
          step: "0.1"
        }}/>
        <TextField fullWidth label="Reason" value={reason} onChange={(e) => setReason(e.target.value)}/>
        <TextField fullWidth label="Person in Charge" value={pic} onChange={(e) => setPic(e.target.value)}/>
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick={() => handleRefund(row.id, row.user?.id, amount, reason, pic)} color="error">Refund</Button>
    </DialogActions>
  </Dialog>
}

export function RefundButton({row}: Props) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleShowDialog = useCallback(() => {
    setOpen(true);
  }, []);

  if (!["golong", "topup"].includes(row.type)) {
    return <></>
  }

  return <>
    <LoadingButton disabled={row.refunded} loading={loading} color="error" onClick={handleShowDialog}>
      {row.refunded ? "Refunded" : "Refund"}
    </LoadingButton>
    <RefundDialog open={open} setOpen={setOpen} setLoading={setLoading} row={row}/>
  </>
}