import { api } from ".";

export async function getPassBatch(params: Partial<{id:string, sort:string, page:number, size: number}>) {
  const response = await api.get("/pass/batch", { params });

  return response.data;
}

export async function postPassBatch(payload: any) {
  const response = await api.post("/pass/batch", payload);

  return response.data;
}

export async function getPass(params: Partial<{ passBatchId: string, sort:string, page:number, size:number }>) {
  const response = await api.get("/pass", { params });

  return response.data;
}

export async function getPassBatchReport(id: string) {
  return api.get<Blob>("/pass/batch/export", { params: { id }, responseType: "blob" });
}
