import { LoadingButton } from "@mui/lab";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom"
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import ReplayIcon from '@mui/icons-material/Replay';
import { GridSortModel } from "@mui/x-data-grid";
import _ from "lodash";

type Props = {
  loading: boolean
  setSortModel: React.Dispatch<React.SetStateAction<GridSortModel>>;
}

export function Filter({loading, setSortModel}:Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [userId, setUserId] = useState(searchParams.get("userId"));
  const [club1933UserId, setClub1933UserId] = useState(searchParams.get("club1933UserId"));
  const [phone, setPhone] = useState(searchParams.get("phone"));
  const [vrm, setVrm] = useState(searchParams.get("vrm"));
  const [company, setCompany] = useState(searchParams.get("company"));
  const [route, setRoute] = useState(searchParams.get("route"));
  const [direction, setDirection] = useState(searchParams.get("direction"));
  const [club1933ShopId, setClub1933ShopId] = useState(searchParams.get("shopId"));
  const [club1933RewardId, setClub1933RewardId] = useState(searchParams.get("rewardId"));
  const [type, setType] = useState(searchParams.get("type"));
  const [txnDate, setTxnDate] = useState<moment.Moment|null>(searchParams.get("txnDate") ? moment(searchParams.get("txnDate")) : null);
  const [txnTimeStart, setTxnTimeStart] = useState<moment.Moment|null>(searchParams.get("txnTimeStart") ? moment(searchParams.get("txnTimeStart")) : null);
  const [txnTimeEnd, setTxnTimeEnd] = useState<moment.Moment|null>(searchParams.get("txnTimeEnd") ? moment(searchParams.get("txnTimeEnd")) : null);

  const handleResetDateTime = useCallback(() => {
    setTxnDate(null);
    setTxnTimeStart(null);
    setTxnTimeEnd(null);
  }, []);

  const handleSearch = useCallback(() => {
    setSearchParams(prev => {
      const order = prev.get("order");
      return {
        ...(order && { order }),
        ...(userId && { userId }),
        ...(club1933UserId && { club1933UserId }),
        ...(phone && { phone }),
        ...(vrm && { vrm }),
        ...(company && { company }),
        ...(route && { route }),
        ...(direction && { direction }),
        ...(club1933ShopId && { club1933ShopId }),
        ...(club1933RewardId && { club1933RewardId }),
        ...(direction && { direction }),
        ...(type && { type }),
        ...(txnDate && { txnDate: txnDate.format("YYYY-MM-DD") }),
        ...(txnTimeStart && txnTimeEnd && {
          txnTimeStart: txnTimeStart.toISOString(true),
          txnTimeEnd: txnTimeEnd.toISOString(true)
        }),
      }
    });
    setSortModel([]);
  }, [setSearchParams, userId, club1933UserId, phone, vrm, route, direction, club1933ShopId, club1933RewardId, txnDate, txnTimeStart, txnTimeEnd, type, company])

  return <Grid container>
    <Grid item xs={11}>
      <Stack spacing={1}>
        <Stack direction={"row"}>
          <TextField
            fullWidth
            size="small"
            label="User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            label="Club1933 User ID"
            value={club1933UserId}
            onChange={(e) => setClub1933UserId(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Stack>
        <Stack direction={"row"}>
          <FormControl fullWidth size="small">
            <InputLabel>Company</InputLabel>
            <Select
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="KMB">KMB</MenuItem>
              <MenuItem value="LWB">LWB</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            size="small"
            label="Route"
            value={route}
            onChange={(e) => setRoute(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            label="VRM"
            value={vrm}
            onChange={(e) => setVrm(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            label="Direction"
            value={direction}
            onChange={(e) => setDirection(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            label="Club1933 Shop ID"
            value={club1933ShopId}
            onChange={(e) => setClub1933ShopId(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            label="Club1933 Reward ID"
            value={club1933RewardId}
            onChange={(e) => setClub1933RewardId(e.target.value)}
          />
        </Stack>
        <Stack direction={"row"}>
          <FormControl fullWidth size="small">
            <InputLabel>Transaction Type</InputLabel>
            <Select value={type} onChange={(e) => setType(e.target.value)}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="topup">Top Up</MenuItem>
              <MenuItem value="golong">Bus</MenuItem>
              <MenuItem value="daypass">Day Pass</MenuItem>
              <MenuItem value="club1933">Club1933 POS</MenuItem>
              <MenuItem value="refund">Refund</MenuItem>
            </Select>
          </FormControl>
          <DatePicker
            label="Transaction Date"
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small"
              }
            }}
            value={txnDate}
            onChange={setTxnDate}
            format="YYYY-MM-DD"
          />
          <DateTimePicker
            label="Transaction Time (Start)"
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small"
              }
            }}
            value={txnTimeStart}
            onChange={setTxnTimeStart}
            format="YYYY-MM-DD HH:mm:ss"
          />
          <DateTimePicker
            label="Transaction Time (End)"
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small"
              }
            }}
            value={txnTimeEnd}
            onChange={setTxnTimeEnd}
            format="YYYY-MM-DD HH:mm:ss"
          />
          <Button onClick={handleResetDateTime} color="warning" startIcon={<ReplayIcon/>} style={{ width: 300 }}>Reset</Button>
        </Stack>
      </Stack>
    </Grid>
    <Grid item xs={1}>
      <LoadingButton fullWidth style={{ height: "100%" }} loading={loading} onClick={handleSearch}>
        <SearchIcon/>
      </LoadingButton>
    </Grid>
  </Grid>
}