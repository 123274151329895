import { DataGrid, GridColDef, GridSortModel, GridValueFormatterParams } from "@mui/x-data-grid"
import { Filter } from "./Filter";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getTxnReport, getTxnReportExport } from "@utils/api/reports";
import "./index.css";
import { Box, Button } from "@mui/material";

const totalColumnDef:Partial<GridColDef> = {
  headerName: "Total",
  align: "right",
  valueFormatter: ({value}) => value.toFixed(2),
}

const countColumnDef:Partial<GridColDef> = {
  headerName: "Count",
  align: "right",
  width: 80
}

export function TxnReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{date:string, total:number, count:number}[]>([]);

  const [sortModel, setSortModel] = useState<GridSortModel>([{ field:"date", sort: "asc" }]);

  const loadData = useCallback(() => {
    const start = searchParams.get("start");
    const end = searchParams.get("end");
    const granularity = searchParams.get("granularity");

    if (start && end && granularity) {
      setLoading(true);
      getTxnReport(start, end, granularity)
      .then((data) => {
        setData(data)
      })
      .finally(() => setLoading(false));
    }
  }, [searchParams]);

  const downloadReport = useCallback(() => {
    const start = searchParams.get("start");
    const end = searchParams.get("end");
    const granularity = searchParams.get("granularity");

    if (start && end && granularity) {
      getTxnReportExport(start, end, granularity)
      .then(({ data: blob, request }) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        const filename = request.responseURL?.split('#')[0].split('?')[0].split('/').pop();

        link.setAttribute(
          'download',
          filename,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      });
    }
  }, [searchParams]);

  useEffect(loadData, [loadData]);
  
  return <>
    <Filter loading={loading}/>
    <Button color="success" onClick={downloadReport}>Export to XLSX</Button>
    <Box style={{ height: "80vh" }}>
      <DataGrid
        getRowId={row => row.date}
        disableColumnMenu
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={[
          {
            groupId: "bus",
            headerName: "Bus",
            headerAlign: "center",
            children: [
              { field: "bus_count" },
              { field: "bus_total" },
            ]
          },
          {
            groupId: "club1933",
            headerName: "Club1933",
            headerAlign: "center",
            children: [
              { field: "club1933_count" },
              { field: "club1933_total" },
            ]
          },
          {
            groupId: "topup",
            headerName: "Top-up",
            headerAlign: "center",
            children: [
              { field: "topup_count" },
              { field: "topup_total" },
            ]
          },
          {
            groupId: "refund",
            headerName: "Refund",
            headerAlign: "center",
            children: [
              { field: "refund_count" },
              { field: "refund_total" },
            ]
          },
        ]}
        rowHeight={30}
        columns={[
          { field: "date", headerName: "Date" },
          { field: "bus_count", ...countColumnDef },
          { field: "bus_total", ...totalColumnDef },
          { field: "club1933_count", ...countColumnDef},
          { field: "club1933_total", ...totalColumnDef },
          { field: "topup_count", ...countColumnDef },
          { field: "topup_total", ...totalColumnDef },
          { field: "refund_count", ...countColumnDef },
          { field: "refund_total", ...totalColumnDef },
          { field: "count", ...countColumnDef },
          { field: "total", ...totalColumnDef },
        ]}
        rows={data}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        getCellClassName={(params) => params.field.endsWith("total") ? "amount" : ""}
      />
    </Box>
  </>
}