import { Home } from "./Home";
import { Login } from "./Login";
import { ProtectedRoute } from "@components/ProtectedRoute";
import { Logout } from "./Logout";
import { POSManagement } from "./POSManagement";
import { Transaction } from "./Transaction";
import { User } from "./User";
import { UserAll } from "./UserAll";
import { Reports } from "./Reports";
import { TxnReport } from "./Reports/TxnReport";
import { PassBatchList } from "./PassBatchList";
import { PassBatchCreate } from "./PassBatchCreate";
import { PassBatchDetails } from "./PassBatchDetails";

export const pages:{
  path: string,
  name: string,
  element: JSX.Element,
  requiredRoles?: string[]
  hidden?: boolean
  hideAppbar?: boolean
}[] = [
  {
    path: "/login",
    name: "Login",
    element: <Login/>,
    hidden: true,
    hideAppbar: true
  },
  {
    path: "/",
    name: "Home",
    element: <ProtectedRoute><Home/></ProtectedRoute>
  },
  {
    path: "/txn",
    name: "Transactions",
    element: <ProtectedRoute><Transaction/></ProtectedRoute>
  },
  {
    path: "/user",
    name: "User",
    element: <ProtectedRoute><User/></ProtectedRoute>
  },
  {
    path: "/userAll",
    name: "User (All)",
    element: <ProtectedRoute><UserAll/></ProtectedRoute>
  },
  {
    path: "/reports",
    name: "Reports",
    element: <ProtectedRoute><Reports/></ProtectedRoute>
  },
  {
    path: "/reports/txnReport",
    name: "Reports",
    hidden: true,
    element: <ProtectedRoute><TxnReport/></ProtectedRoute>
  },
  {
    path: "/pass/batch",
    name: "Pass Batch",
    element: <ProtectedRoute><PassBatchList/></ProtectedRoute>
  },
  {
    path: "/pass/batch/create",
    name: "Pass Batch",
    hidden: true,
    element: <ProtectedRoute><PassBatchCreate/></ProtectedRoute>
  },
  {
    path: "/pass/batch/details/:id",
    name: "Pass Batch Details",
    hidden: true,
    element: <ProtectedRoute><PassBatchDetails/></ProtectedRoute>
  },
  {
    path: "/logout",
    name: "Logout",
    element: <Logout/>
  },
]