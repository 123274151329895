import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Filter } from './Filter';
import { txnGet, txnGetExport } from '@utils/api/txn';
import { Txn } from '@utils/types/Txn';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { RefundButton } from './RefundButton';
import { Button, Grid, Link, Typography } from '@mui/material';
import RefreshIcon from "@mui/icons-material/Refresh";

import "./index.css";
import { LoadingButton } from '@mui/lab';
import { toast } from "react-toastify";

export function Transaction() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [downloading, setDownloading] = useState(false);

  const columns = useMemo<GridColDef<Txn>[]>(() => [
    // When updated, remember to update the export function in the backend
    { field: "userId", headerName: "User ID", renderCell: ({row}) => <Link href={`/user?id=${row.user.id}`} target="_blank">{row.user.id}</Link> },
    { field: "phone", headerName: "Phone", valueGetter: ({row}) => row.user.phone },
    { field: "type", valueGetter: ({row}) => row.type },
    { field: "txnDate" },
    { field: "txnTime", width: 180, valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD HH:mm:ss") },
    { field: "amount", align: "right", renderCell: ({row}) => <Typography style={{ fontFamily: "monospace"}}>{row.amount}</Typography> },
    { field: "posId", headerName: "Device Number", width: 150 },
    { field: "vrm", headerName: "Bus Number" },
    { field: "company", valueGetter: ({row}) => row.routeMap?.company },
    { field: "route", valueGetter: ({row}) => row.routeMap?.name || row.route },
    { field: "direction" },
    { field: "balance", headerName: "Account Balance", width: 150, align: "right", renderCell: ({row}) => <Typography style={{ fontFamily: "monospace"}}>{row.balance}</Typography> },
    { field: "verified", headerName: "Settlement Status", width: 150, align: "center", valueGetter: ({row}) => row.verifyErrorMessage ? `❌ ${row.verifyErrorMessage}` : (row.verified ? "✔️" : "-")  },
    { field: "verifyTime", headerName: "Settlement Time", width: 180, valueFormatter: (params) => params.value && moment(params.value).format("YYYY-MM-DD HH:mm:ss") },
    { field: "club1933ShopId", headerName: "Shop ID" },
    { field: "club1933RewardId", headerName: "Reward ID" },
    { field: "refund", renderCell: ({row}) => <RefundButton row={row}/>, sortable: false},
    { field: "refundReason", valueGetter: ({row}) => row.refTxn?.refundReason || row.refundReason },
    { field: "refundPic", valueGetter: ({row}) => row.refTxn?.refundPic || row.refundPic },
    { field: "adminUsername", valueGetter: ({row}) => row.adminUsername },
  ], []);
  const [data, setData] = useState<Txn[]>([]);
  const [count, setCount] = useState(0);
  const filter = useMemo(() => {
    return _.fromPairs([...searchParams.entries()])
  }, [searchParams]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });

  const handleLoadData = useCallback(() => {
    setLoading(true);
    txnGet(filter, paginationModel.page, paginationModel.pageSize).then(response => {
      setCount(response.count);
      setData(response.rows);
    })
    .catch(error => {
      window.alert(error.response?.data?.message || error.message);
    })
    .finally(() => {
      setLoading(false);
    })
  }, [filter, paginationModel]);

  const downloadReport = useCallback(() => {
    if (count > 20_000) {
      toast.error(
        "You may only export up to 20,000 records at a time. Please narrow down your search."
      );
      return;
    }
    setDownloading(true);
    txnGetExport(filter)
      .then(({ data: blob, request }) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        const filename = request.responseURL
          ?.split("#")[0]
          .split("?")[0]
          .split("/")
          .pop();

        link.setAttribute("download", filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      })
      .finally(() => {
        setDownloading(false);
      });
  }, [count, filter]);

  const handleSortModelChange = useCallback((sortModel:GridSortModel) => {
    searchParams.set("order", sortModel.map(x => `${x.field}|${x.sort}`).join(","));
    setSearchParams(searchParams);
  }, [searchParams]);

  useEffect(handleLoadData, [handleLoadData]);
  
  return <>
    <Filter loading={loading} setSortModel={setSortModel}/>
      <Grid container>
        <Grid item xs>
          <LoadingButton
            fullWidth
            color="success"
            loading={loading}
            onClick={handleLoadData}
            startIcon={<RefreshIcon />}
          >
            Refresh
          </LoadingButton>
        </Grid>
        <Grid item xs={1}>
          <LoadingButton fullWidth color="success" loading={downloading} onClick={downloadReport}>
            Export to XLSX
          </LoadingButton>
        </Grid>
      </Grid>
      <DataGrid
      loading={loading}
      autoHeight
      paginationMode={"server"}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      getRowClassName={({row}) => {
        if (row.refunded) {
          return "refunded";
        } else if (row.type === "refund") {
          return "refund-txn";
        }
        return "";
      }}
      sortModel={sortModel}
      sortingMode="server"
      onSortModelChange={(model) => {
        setSortModel(model);
        handleSortModelChange(model);
      }}
      rowCount={count}
      columns={columns}
      rows={data}
      disableColumnMenu
    />
  </>
}