import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import "./index.css";

import { AppBar } from '@components/AppBar';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@utils/theme';
import { pages } from './pages';
import { RBACWrapper } from 'react-simple-rbac';
import { Forbidden } from '@pages/Forbidden';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CognitoProvider } from '@components/CognitoProvider';
import { RBACProviderWrapper } from '@components/RBACProviderWrapper';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <CognitoProvider>
      <QueryClientProvider client={queryClient}>
        <RBACProviderWrapper>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <RouterProvider router={createBrowserRouter(pages.map(page => ({
                ...page,
                element: <RBACWrapper requiredRoles={page.requiredRoles} oneOf fallback={<Forbidden/>}>
                  <>
                    {!page.hideAppbar && <AppBar/>}
                    {page.element}
                  </>
                </RBACWrapper>
              })))}/>
              <ToastContainer />
            </LocalizationProvider>
          </ThemeProvider>
        </RBACProviderWrapper>
      </QueryClientProvider>
    </CognitoProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
