import { LoadingButton } from "@mui/lab";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { DurationControl } from "./DurationControl";
import { FormEventHandler, useCallback, useState } from "react";
import { postPassBatch } from "@utils/api/pass";
import { toast } from "react-toastify";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";

export function PassBatchCreate() {
  const navigate = useNavigate();

  const [sending, setSending] = useState(false);
  const [displayNameTc, setDisplayNameTc] = useState("");
  const [displayNameEn, setDisplayNameEn] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [basePrice, setBasePrice] = useState(1000);
  const [validDuration, setValidDuration] = useState("");
  const [redeemExpiryTime, setRedeemExpiryTime] = useState<moment.Moment|null>(moment().add(2, "years").endOf("day"));
  const [remarks, setRemarks] = useState("");

  const handleSave = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault();

    setSending(true);
    postPassBatch({
      quantity,
      validDuration,
      redeemExpiryTime: redeemExpiryTime?.format("YYYY-MM-DD HH:mm:ss"),
      displayNameTc,
      displayNameEn,
      basePrice,
      remarks
    })
    .then(() => {
      toast.success("Passes Generated");
      navigate("/pass/batch");
    })
    .finally(() => {
      setSending(false);
    });
  }, [quantity, validDuration, redeemExpiryTime, remarks, displayNameTc, displayNameEn, basePrice]);

  return <Box component="form" onSubmit={handleSave}>
    <Stack spacing={2}>

    <Stack direction="row" spacing={1}>
      <TextField required fullWidth label="Display Name (TC)" value={displayNameTc} onChange={(e) => setDisplayNameTc(e.target.value)}/>
      <TextField required fullWidth label="Display Name (EN)" value={displayNameEn} onChange={(e) => setDisplayNameEn(e.target.value)}/>
    </Stack>

    <Stack direction="row" spacing={1}>
      <TextField required fullWidth label="Quantity" inputProps={{ type: 'number', min: 1, inputMode: "numeric", pattern: "[0-9]*" }} value={quantity} onChange={(e) => setQuantity(Number(e.target.value))}/>
      <TextField required fullWidth label="Base Price" inputProps={{ type: 'number', min: 1, step: 0.1 }} value={basePrice.toFixed(2)} onChange={(e) => setBasePrice(Number(e.target.value))}/>
      <DatePicker sx={{ width: "100%" }} label="Pass Expiry Time" format="YYYY-MM-DD" value={redeemExpiryTime} onChange={(e) => setRedeemExpiryTime(e)}/>
    </Stack>

    <Stack spacing={1}>
      <Typography>Effective Duration after activation</Typography>
      <DurationControl duration={validDuration} setDuration={setValidDuration}/>
    </Stack>


    <TextField label="Remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)}/>

    <LoadingButton type="submit" color="success" loading={sending}>Create</LoadingButton>
    </Stack>
  </Box>
}