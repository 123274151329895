import { CircularProgress } from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import { RBACProvider } from "react-simple-rbac";
import { getRoles } from "@utils/cognito";

export function RBACProviderWrapper(props: PropsWithChildren) {
  const [roles, setRoles] = useState<string[]|undefined>();

  useEffect(() => {
    setRoles(getRoles());
  }, []);

  if (!roles) {
    return <CircularProgress/>
  }

  return <RBACProvider roles={roles}>
    {props.children}
  </RBACProvider>
}