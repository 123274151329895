import { CircularProgress } from "@mui/material";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { PropsWithChildren, useEffect, useState } from "react";
import { loadSignInUserSession } from "@utils/cognito";

export function CognitoProvider(props: PropsWithChildren) {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser|null|undefined>();
  const [userSession, setUserSession] = useState<CognitoUserSession|null|undefined>();

  useEffect(() => {
    loadSignInUserSession().then(({user, session}) => {
      setCognitoUser(user);
      setUserSession(session);
    }).catch(error => {
      window.alert(error.message);
    });
  }, []);

  useEffect(() => {
    console.log("[CognitoProvider]", "cognitoUser", cognitoUser);
  }, [cognitoUser]);

  useEffect(() => {
    console.log("[CognitoProvider]", "userSession", userSession);
  }, [userSession]);

  if (cognitoUser === undefined || userSession === undefined) {
    return <CircularProgress/>
  }
  
  return <>{props.children}</>;
}