import { Button, Stack } from '@mui/material';
import { pages } from '@pages/index';
import { getRoles } from '@utils/cognito';

export const Home = function () {
  return <Stack spacing={2}>
    {pages
      .filter(page => page.name !== "Home")
      .filter(page => !page.hidden)
      .filter(page => !page.requiredRoles || page.requiredRoles?.find(role => getRoles().includes(role)))
      .map(page => <Button key={page.name} fullWidth href={page.path} variant={"contained"}>
      {page.name}
    </Button>)}
  </Stack>;
}
