import { DatePicker } from "@mui/x-date-pickers"
import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const granularityList = [
  { name: "Daily", value: "day" },
  { name: "Monthly", value: "month" },
  // "week",
  // "month",
  // "quarter",
  // "year"
];

type Props = {
  loading: boolean
}

export function Filter({ loading }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [start, setStart] = useState<moment.Moment|null>(null);
  const [end, setEnd] = useState<moment.Moment|null>(null);
  const [granularity, setGranularity] = useState("day");

  const [validateResult, setValidateResult] = useState<{start:string|null,end:string|null}>({
    start: null,
    end: null
  });

  const validate = useCallback(() => {
    setValidateResult({
      start: !start ? "No start date specified" : null,
      end: !end ? "No end date specified" : null,

    })
  }, [start, end, granularity]);

  const handleSearch = useCallback(() => {
    validate();
    if (start && end && granularity) {
      setSearchParams({
        start: start.format("YYYY-MM-DD"),
        end: end.format("YYYY-MM-DD"),
        granularity
      });
    }
  }, [validate, start, end, granularity]);

  useEffect(() => {
    setStart(searchParams.has("start") ? moment(searchParams.get("start")) : null);
    setEnd(searchParams.has("end") ? moment(searchParams.get("end")) : null);
    setGranularity(searchParams.get("granularity") || "day");
  }, [searchParams]);

  return <Stack>
    <Stack direction="row">
      <DatePicker
        label="Transaction Date (Start)"
        slotProps={{
          textField: {
            fullWidth: true,
            size: "small",
            error: !!validateResult.start,
            helperText: validateResult.start
          }
        }}
        value={start}
        onChange={setStart}
        format="YYYY-MM-DD"
      />
      <DatePicker
        label="Transaction Date (End)"
        slotProps={{
          textField: {
            fullWidth: true,
            size: "small",
            error: !!validateResult.end,
            helperText: validateResult.end
          }
        }}
        value={end}
        onChange={setEnd}
        format="YYYY-MM-DD"
      />
      <FormControl fullWidth>
        <InputLabel>Granularity</InputLabel>
        <Select
          size="small"
          value={granularity}
          onChange={e => setGranularity(e.target.value)}
        >
          {granularityList.map(item => <MenuItem value={item.value}>{item.name}</MenuItem>)}
        </Select>
      </FormControl>
    </Stack>
    <LoadingButton
      loading={loading}
      onClick={handleSearch}
    >
      <SearchIcon/>
    </LoadingButton>
  </Stack>
}