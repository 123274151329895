import { start } from "repl";
import { api } from ".";

export async function getTxnReport(start:string, end:string, granularity:string) {
  const { data } = await api.get<{date:string, count:number, total:number}[]>("/report/txnReport", { params: {
    start,
    end,
    granularity,
    exportReport: false
  }});

  return data;
}

export async function getTxnReportExport(start:string, end:string, granularity:string) {
  return api.get<Blob>("/report/txnReport", {
    responseType: "blob",
    params: {
      start,
      end,
      granularity,
      exportReport: true
    }
  });
}