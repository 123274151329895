import { Txn } from "@utils/types/Txn";
import { api } from ".";

export async function txnGet(params: any, page:number, size:number) {
  const response = await api.get<{
    count: number,
    rows: Txn[]
  }>("/txn", { params: {
    ...params,
    page,
    size,
    exportReport: false
  } });

  return response.data;
}

export async function txnGetExport(params: any) {
  return api.get<Blob>("/txn/export", {
    responseType: "blob",
    params: {
      ...params,
      exportReport: true,
    },
  });
}

export async function txnRefundPost(id:string, amount:number, reason:string, pic:string) {
  const response = await api.post("/txn/refund", {
    id,
    amount,
    reason,
    pic
  });

  return response.data;
}