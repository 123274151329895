import { Button, Stack, TextField, Typography } from '@mui/material';
import { cognitoAuthenticate, cognitoCompleteNewPasswordChallenge } from '@utils/cognito';
import React, { KeyboardEventHandler, useCallback, useState } from 'react';
import { useRBACContext } from 'react-simple-rbac';

export const Login = function () {
  const rbac = useRBACContext();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = useCallback(() => {
    cognitoAuthenticate(username, password, {
      onSuccess: (session) => {
        rbac.addRoles(session.getAccessToken().payload["cognito:groups"]);
        window.location.href = "/";
      },
      onFailure: (error) => {
        console.error(error);
        window.alert(error.message);
      },
      newPasswordRequired: (userAttributes) => {
        cognitoCompleteNewPasswordChallenge(password, userAttributes, {
          onSuccess: (session) => {
            rbac.addRoles(session.getAccessToken().payload["cognito:groups"]);
            window.location.href = "/";
          },
          onFailure: (error) => {
            console.error(error);
            window.alert(error.message);
          },
        })
      }
    })
  }, [username, password, rbac]);

  const handleEnterKey:KeyboardEventHandler = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLogin();
    }
  }, [handleLogin]);

  return <Stack spacing={2}>
    <Typography style={{ textAlign: "center" }} variant={"h3"}>KMB e-Wallet Admin Dashboard</Typography>
    <TextField fullWidth value={username} onChange={e => setUsername(e.target.value)} onKeyDown={handleEnterKey} label={"Username"} />
    <TextField fullWidth value={password} onChange={e => setPassword(e.target.value)} onKeyDown={handleEnterKey} label={"Password"} type={"password"}/>
    <Stack direction={"row"}>
      <Button fullWidth onClick={handleLogin}>Login</Button>
    </Stack>
  </Stack>
}
